import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import type { RootState } from '../store';

export const appBaseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    return headers;
  },
});

export const alertBaseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_ALERT_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    return headers;
  },
});

export const streamBaseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_SENSOR_STREAM_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    return headers;
  },
});
