import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type AuthState = {
  token: string;
};

const defaultState: AuthState = {
  token: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: defaultState,
  reducers: {
    setAuth: (state, action: PayloadAction<AuthState>) => {
      return { ...action.payload };
    },
    clearAuth: () => {
      return { ...defaultState };
    },
  },
});

const { reducer, actions } = authSlice;
export const { setAuth, clearAuth } = actions;
export default reducer;
