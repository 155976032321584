import { createApi } from '@reduxjs/toolkit/query/react';
import { OpenAPI3 } from '../interfaces/openapi';
import { streamBaseQuery } from '../utils/baseQuery';

export const streams = createApi({
  reducerPath: 'streams',
  baseQuery: streamBaseQuery,
  endpoints: (builder) => ({
    getSensorStreamOpenApi: builder.query<OpenAPI3, void>({
      query: () => ({
        url: 'openapi.json',
      }),
    }),
  }),
});

export const { useGetSensorStreamOpenApiQuery } = streams;
