import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import React from 'react';
import { useGetAlertsOpenApiQuery } from '../../services/alerts';
import { useGetNodeServerOpenApiQuery } from '../../services/sensors';
import { useGetSensorStreamOpenApiQuery } from '../../services/streams';
import { useParams, useNavigate } from 'react-router-dom';
import Loading from '../../components/Loading';
import { useSelector } from 'react-redux';
import type { RootState } from '../../store';
import styles from './styles.module.scss';
import { OpenAPI3 } from '../../interfaces/openapi';

interface Request {
  [k: string]: any;
}

function addAuthHeader(req: Request, token: string): Request {
  req.headers.Authorization = `Bearer ${token}`;
  return req;
}

function setServer(openApi: OpenAPI3, url?: string): OpenAPI3 {
  if (url) {
    return {
      ...openApi,
      servers: [{ url }],
    };
  }
  return openApi;
}

const Docs: React.FC = () => {
  const { service } = useParams();
  const navigate = useNavigate();
  const authToken = useSelector((state: RootState) => state.auth.token);

  const selected = {
    'node-server': {
      query: useGetNodeServerOpenApiQuery,
      serverUrl: process.env.REACT_APP_BASE_URL,
    },
    'sensor-stream': {
      query: useGetSensorStreamOpenApiQuery,
      serverUrl: process.env.REACT_APP_SENSOR_STREAM_URL,
    },
    alerts: {
      query: useGetAlertsOpenApiQuery,
      serverUrl: process.env.REACT_APP_ALERT_URL,
    },
  }[service ?? 'node-server'] ?? {
    query: useGetNodeServerOpenApiQuery,
    serverUrl: process.env.REACT_APP_BASE_URL,
  };

  const { data, isError } = selected.query();

  if (isError) {
    return <p> something went wrong</p>;
  }

  return data ? (
    <div>
      <div className={styles.selectContainer}>
        <label htmlFor="service-select">Select a service:</label>
        <select
          id="service-select"
          value={service}
          onChange={(el) => navigate(`/${el.target.value}`)}
        >
          {['node-server', 'sensor-stream', 'alerts'].map((s) => (
            <option key={s} value={s}>
              {s}
            </option>
          ))}
        </select>
      </div>
      <SwaggerUI
        spec={setServer(data, selected.serverUrl)}
        requestInterceptor={(req: Request) => addAuthHeader(req, authToken)}
      />
    </div>
  ) : (
    <Loading />
  );
};

export default Docs;
