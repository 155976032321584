import * as React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';

const Redirect: React.FC = () => (
  <div>
    <p>Redirect....</p>
  </div>
);

const withAuthRedirect = (
  component: React.FC,
  RedirectingComponent: React.FC = Redirect
): React.FC =>
  withAuthenticationRequired(component, {
    onRedirecting: () => <RedirectingComponent />,
  });

export default withAuthRedirect;
