import React from 'react';
import styles from './styles.module.scss';

const Loading: React.FC = () => {
  return (
    <div className={`d-flex justify-content-center ${styles.loadingContainer}`}>
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

export default Loading;
