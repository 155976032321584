import { Auth0Provider } from '@auth0/auth0-react';
import { Provider } from 'react-redux';
import store from '../../store';
import App from '../App';

const Auth0Wrapper: React.FC = () => {
  const onRedirectCallback = (appState: any) => {
    const returnTo = appState?.returnTo ?? window.location.href;
    if (returnTo !== '/') {
      window.location.replace(returnTo);
    }
  };

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH_DOMAIN || ''}
      clientId={process.env.REACT_APP_AUTH_CLIENT_ID || ''}
      audience={process.env.REACT_APP_AUTH_AUDIENCE}
      redirectUri={window.location.origin}
      cacheLocation={'localstorage'}
      useRefreshTokens={true}
      onRedirectCallback={onRedirectCallback}
    >
      <Provider store={store}>
        <App />
      </Provider>
    </Auth0Provider>
  );
};

// expose store when run in Cypress
if ((window as any).Cypress) {
  (window as any).store = store;
}

export default Auth0Wrapper;
