import Docs from '../pages/Docs';
import withAuthRedirect from './withAuthRedirect';

const routes = [
  {
    path: '/:service',
    component: withAuthRedirect(Docs),
  },
  {
    path: '/',
    component: withAuthRedirect(Docs),
  },
];

export default routes;
