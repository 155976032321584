import { configureStore } from '@reduxjs/toolkit';
import { sensors, alerts, streams } from '../services';
import authReducer from './reducers/auth';

const store = configureStore({
  reducer: {
    [sensors.reducerPath]: sensors.reducer,
    [alerts.reducerPath]: alerts.reducer,
    [streams.reducerPath]: streams.reducer,
    auth: authReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([sensors.middleware, alerts.middleware, streams.middleware]),
  devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
