import { createApi } from '@reduxjs/toolkit/query/react';
import { OpenAPI3 } from '../interfaces/openapi';
import { alertBaseQuery } from '../utils/baseQuery';

export const alerts = createApi({
  reducerPath: 'alerts',
  baseQuery: alertBaseQuery,
  endpoints: (builder) => ({
    getAlertsOpenApi: builder.query<OpenAPI3, void>({
      query: () => ({
        url: 'openapi.json',
      }),
    }),
  }),
});

export const { useGetAlertsOpenApiQuery } = alerts;
