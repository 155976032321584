import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Loading from '../../components/Loading';
import routes from '../../utils/routes';
import { useAppDispatch } from '../../utils/hooks';
import { setAuth } from '../../store/reducers/auth';
import type { RootState } from '../../store';
import { useSelector } from 'react-redux';

import '../../styles/main.scss';

const App: React.FC = () => {
  const { loginWithRedirect, isAuthenticated, isLoading, error, getAccessTokenSilently } =
    useAuth0();
  const authToken = useSelector((state: RootState) => state.auth.token);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently().then((t) => {
        dispatch(setAuth({ token: t }));
      });
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated && !isLoading && !error) {
      loginWithRedirect({
        appState: {
          returnTo: window.location.pathname,
        },
      });
    }
  }, [isAuthenticated, isLoading, error]);

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    console.log(error);
    return <div>oops...</div>;
  }

  return (
    <BrowserRouter>
      <Routes>
        {authToken ? (
          <Route path="/">
            {routes.map((route, idx) => {
              const Component = route.component;
              return <Route key={idx} path={route.path} element={<Component />} />;
            })}
            <Route path="/" element={<Navigate to={`/`} replace />} />
            <Route path="*" element={<Navigate to={`/`} replace />} />
          </Route>
        ) : (
          <Route path="/" element={<Loading />} />
        )}
      </Routes>
    </BrowserRouter>
  );
};

export default App;
