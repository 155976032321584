import { createApi } from '@reduxjs/toolkit/query/react';
import { appBaseQuery } from '../utils/baseQuery';
import { OpenAPI3 } from '../interfaces/openapi';

export const sensors = createApi({
  reducerPath: 'sensors',
  baseQuery: appBaseQuery,
  endpoints: (builder) => ({
    getNodeServerOpenApi: builder.query<OpenAPI3, void>({
      query: () => ({
        url: 'openapi.json',
      }),
    }),
  }),
});

export const { useGetNodeServerOpenApiQuery } = sensors;
